<template>
    <div>
        <div style="display:flex;align-items: center;justify-content: space-between;">
            <p class="title1">
                配送信息
                <!-- <span style="color:#999;font-size: 13px;" v-if="isEdit">产品类型为“定制提货券”则不需要填写配送信息</span> -->
                <a-button type="primary" style="margin-left: 10px;" @click="addAddress"
                    v-if="isEdit && addressType != 3">添加收货地址</a-button>
            </p>
            <!-- isEdit = false 说明在需求单详情仅展示使用 -->
            <div v-if="isEdit && addressType != 3">
                <a-button type="primary" style="margin-right: 10px;" @click="downLoadFile">下载模板</a-button>

                <a-upload :file-list="fileList" @change="importAddress" :multiple="false" :data="postData"
                    :action="qiNiuAction">
                    <a-button v-if="fileList.length == 0" type="primary">
                        上传文档 </a-button>
                </a-upload>
            </div>

        </div>
        <div style="margin-bottom: 10px;">
            <a-radio-group v-model="addressType" :disabled="!isEdit" style="margin-top: 20px;"
                @change="changeAddressType">
                <a-radio :value="1">部分收货地址</a-radio>
                <a-radio :value="2">全部收货地址</a-radio>
                <a-radio :value="3">暂无收货地址</a-radio>
            </a-radio-group>
            <span v-if="isEdit" style="color:red;">*请根据客户需求填写实际收货地址信息，未如实填写会影响发货、报价、商品库存不足等问题</span>
        </div>
        <div v-if="addressType == 3">
            <a-input :disabled="!isEdit" placeholder="请详细填写收货地址、数量、城市、是否需搬运上楼等相关信息。。。" @change="addressInfoBlur"
                v-model="form.addressTypeInfo"></a-input>
        </div>
        <div style="border: 1px solid #f0f0f0;" v-if="addressType != 3">
            <a-table :columns="columns" :dataSource="dataSource" :loading="tabLoading" :pagination="false"
                :scroll="{ x: type == 2 ? 1100 : 1200 }">
                <!-- this.addressForm.expectStartTime = dateString[0] + ' 00:00:00'
                this.addressForm.expectEndTime -->
                <template slot="expectStartTime" slot-scope="record,text">
                    {{ text.expectStartTime }}-{{ text.expectEndTime }}
                </template>
                <template slot="action" slot-scope="record,text">
                    <div v-if="isEdit">
                        <a @click="edit(text)" style="margin-right: 10px">编辑</a>
                        <a-popconfirm title="你确定要删除吗？" ok-text="确定" cancel-text="取消" @confirm="del(text)">
                            <a style="color:red;">删除</a>
                        </a-popconfirm>
                    </div>
                </template>
            </a-table>
            <div class="page-box">
                <span style="margin-right: 10px;font-size: 15px;">共{{ total }}条</span>
                <a-pagination :total="total" :current="queryData.page + 1" :pageSize="queryData.size"
                    @change="handlePageChange" />
            </div>
        </div>
        <a-modal :title="title" :visible="openAddress" :confirm-loading="confirmLoading" :maskClosable="false"
            :footer="null" @cancel="cancelChangeAddress" :width="600" :z-index="101">
            <a-form-model ref="ruleAddForm" :model="addressForm" :rules="addressRules">
                <a-form-model-item label="收货人姓名" prop="name" :labelCol="{ span: 6 }"
                    :wrapperCol="{ span: 14, offset: 0 }">
                    <a-input style="width: 100%;" v-model="addressForm.name" placeholder="请输入收货人姓名" />
                </a-form-model-item>
                <a-form-model-item label="收货人电话" prop="mobile" :labelCol="{ span: 6 }"
                    :wrapperCol="{ span: 14, offset: 0 }">
                    <a-input style="width: 100%;" :max-length="11" v-model="addressForm.mobile"
                        placeholder="请输入收货人电话" />
                </a-form-model-item>
                <a-form-model-item label="省" prop="provinceId" :labelCol="{ span: 6 }"
                    :wrapperCol="{ span: 14, offset: 0 }">
                    <a-select allowClear @change="provChange" v-model="addressForm.provinceDesc"
                        :filter-option="(input, option) => { return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0; }"
                        show-search placeholder="请选择省份" option-label-prop="label">
                        <a-select-option v-for="item in prov" :key="item.id" :value="item.id" :label="item.name">
                            {{ item.name }}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item label="市" prop="cityId" :labelCol="{ span: 6 }"
                    :wrapperCol="{ span: 14, offset: 0 }">
                    <a-select @change="cityChange" allowClear placeholder="请选择市" v-model="addressForm.cityDesc"
                        :filter-option="(input, option) => { return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0; }"
                        show-search option-label-prop="label">
                        <a-select-option v-for="item in city" :key="item.id" :value="item.id" :label="item.name">
                            {{ item.name }}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item label="区" prop="areaId" :labelCol="{ span: 6 }"
                    :wrapperCol="{ span: 14, offset: 0 }">
                    <a-select @change="areaChange" allowClear placeholder="请选择区" v-model="addressForm.areaDesc"
                        :filter-option="(input, option) => { return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0; }"
                        show-search option-label-prop="label">
                        <a-select-option v-for="item in area" :key="item.id" :value="item.id" :label="item.name">
                            {{ item.name }}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item label="详细地址" prop="addressDetail" :labelCol="{ span: 6 }"
                    :wrapperCol="{ span: 14, offset: 0 }">
                    <a-input style="width: 100%;" v-model="addressForm.addressDetail" placeholder="请输入详细地址" />
                </a-form-model-item>

                <!-- 货堆 -->
                <div v-if="type == 1">
                    <a-form-model-item label="配送类型" prop="deliveryType" :labelCol="{ span: 6 }"
                        :wrapperCol="{ span: 14, offset: 0 }">
                        <a-select v-model="addressForm.deliveryType" style="width: 200px;" size="middle"
                            placeholder="请选择配送类型" :default-value="null" option-label-prop="label">
                            <a-select-option :value="item.id" :label="item.name"
                                v-for=" (item, index) in [{ id: 4, name: '未知' }, { id: 1, name: '直接送达' }, { id: 2, name: '配送到公司-公司配送' }, { id: 3, name: '配送到公司-销售自送' }]"
                                :key="index">
                                {{ item.name }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item label="是否搬运" prop="carryType" :labelCol="{ span: 6 }"
                        :wrapperCol="{ span: 14, offset: 0 }">
                        <a-select v-model="addressForm.carryType" style="width: 200px;" size="middle"
                            placeholder="请选择是否搬运" :default-value="null" option-label-prop="label">
                            <a-select-option :value="item.id" :label="item.name"
                                v-for=" (item, index) in [{ id: 3, name: '未知' }, { id: 1, name: '需要搬运' }, { id: 2, name: '无需搬运' }]"
                                :key="index">
                                {{ item.name }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item label="预计送达时间" required :labelCol="{ span: 6 }"
                        :wrapperCol="{ span: 14, offset: 0 }">
                        <!-- expectStartTime -->
                        <!-- <a-date-picker style="width: 100%;" v-model="form.expectStartTime" placeholder="请选择预计送达时间" /> -->
                        <a-range-picker allowClear @change="dateChange" :show-time="{ format: 'HH:mm' }"
                            format="YYYY-MM-DD HH:mm" :value="dateValue" />

                    </a-form-model-item>
                    <a-form-model-item label="特殊备注" :labelCol="{ span: 6 }" :wrapperCol="{ span: 14, offset: 0 }">
                        <a-input style="width: 100%;" v-model="addressForm.remark" :max-length="20"
                            placeholder="请输入送货特殊需求备注" />
                    </a-form-model-item>
                </div>
            </a-form-model>
            <div style="display: flex;justify-content: center;">
                <a-button type="primary" @click="handleOkAddress" style="margin-right: 10px;margin-top: 24px;"
                    :loading="tabLoading">保存</a-button>
            </div>
        </a-modal>
    </div>
</template>
<script>
import { request } from '@/utils/request'
export default {
    props: {
        type: {
            // 1货堆 2宅配
            type: Number,
            default: 1,
        },
        isEdit: {
            type: Boolean,
            default: true
        },
        // 子需求单id
        needId: {
            type: String,
            defalut: "",
        },
        needOrderDetail: {
            type: Number,
            defalut: null
        }
    },
    data() {
        return {
            tabLoading: false,
            addressType: 1,
            form: {},
            rules: {},
            openAddress: false,
            addressForm: {},
            addressRules: {
                name: [{ required: true, message: '请输入收货人姓名', trigger: 'blur' }],
                mobile: [{ required: true, message: '请输入收货人电话', trigger: 'blur' }],
                provinceId: [{ required: true, message: '请选择省', trigger: 'change' }],
                cityId: [{ required: true, message: '请选择市', trigger: 'change' }],
                areaId: [{ required: true, message: '请选择区', trigger: 'change' }],
                addressDetail: [{ required: true, message: '请输入详细地址', trigger: 'blur' }],
            },
            prov: [],
            city: [],
            area: [],
            dataSource: [],
            columns: [
                // {title: '序号',key: 'index',},
                { title: '收货人姓名', dataIndex: 'name', align: 'center' },
                { title: '收货人电话', dataIndex: 'mobile', align: 'center' },
                { title: '省', dataIndex: 'provinceDesc', align: 'center' },
                { title: '市', dataIndex: 'cityDesc', align: 'center' },
                { title: '区', dataIndex: 'areaDesc', align: 'center' },
                { title: '详细地址', dataIndex: 'addressDetail', align: 'center' },
                { title: '操作', dataIndex: 'action', align: 'center', scopedSlots: { customRender: 'action' }, fixed: 'right', width: 150 },
            ],
            title: '新增',
            dateValue: "",
            addressTypeInfo: "",
            queryData: {
                page: 0,
                size: 10
            },
            qiNiuAction: 'https://upload-z1.qiniup.com',
            getQiniuTokenIng: false,
            postData: {
                token: ''
            },
            fileList: [],
            currentPage: 0,
            total: 0,
            batchId: '',//地址批次id
            confirmLoading: false,
        }
    },
    watch: {
        needOrderDetail(newVal) {
            console.log(newVal, 'val')
            // if (newVal) {
            this.addressType = newVal.addressType;
            this.form.addressTypeInfo = newVal.addressTypeInfo;
            this.queryData.batchId = newVal.addressBatchId
            this.getAddressList()
            console.log(this.form.addressTypeInfo, 'this.form.addressTypeInfo')
            // }
        }
    },
    mounted() {
        this.getCity();
        if (this.needOrderDetail) {
            this.addressType = this.needOrderDetail.addressType;
            this.form.addressTypeInfo = this.needOrderDetail.addressTypeInfo;
        }
        if (this.type == 1) {
            this.columns = [
                // {title: '序号',key: 'index',},
                { title: '收货人姓名', dataIndex: 'name', align: 'center', width: 120 },
                { title: '收货人电话', dataIndex: 'mobile', align: 'center', width: 120 },
                { title: '省', dataIndex: 'provinceDesc', align: 'center', width: 120 },
                { title: '市', dataIndex: 'cityDesc', align: 'center', width: 120 },
                { title: '区', dataIndex: 'areaDesc', align: 'center', width: 120 },
                { title: '详细地址', dataIndex: 'addressDetail', align: 'center', width: 150 },
                { title: '配送类型', dataIndex: 'deliveryTypeDesc', align: 'center', width: 150 },
                { title: '是否搬运', dataIndex: 'carryTypeDesc', align: 'center', width: 150 },
                { title: '预计送达时间', dataIndex: 'expectStartTime', align: 'center', scopedSlots: { customRender: 'expectStartTime' }, width: 170 },
                { title: '特殊备注', dataIndex: 'remark', align: 'center', width: 150 },
                { title: '操作', dataIndex: 'action', align: 'center', scopedSlots: { customRender: 'action' }, fixed: 'right', width: 150 },
            ]
            this.addressRules = {
                name: [{ required: true, message: '请输入收货人姓名', trigger: 'blur' }],
                mobile: [{ required: true, message: '请输入收货人电话', trigger: 'blur' }],
                provinceId: [{ required: true, message: '请选择省', trigger: 'change' }],
                cityId: [{ required: true, message: '请选择市', trigger: 'change' }],
                areaId: [{ required: true, message: '请选择区', trigger: 'change' }],
                addressDetail: [{ required: true, message: '请输入详细地址', trigger: 'blur' }],
                deliveryType: [{ required: true, message: '请选择配送类型', trigger: 'change' }],
                carryType: [{ required: true, message: '请选择是否搬运上楼', trigger: 'change' }],
                // expectStartTime: [{ required: true, message: '请选择客户预计送达时间', trigger: 'change' }],
                remark: [{ required: true, message: '请输入送货特殊备注', trigger: 'blur' }],
            }
        }
        this.getAddressList()
        this.getQiniuToken()
    },
    methods: {
        downLoadFile() {
            if (this.type == 1) {
                window.open('https://qiniu.youjiamall.cn/huo_dui.xlsx')
            }
            // 宅配
            if (this.type == 2) {
                window.open('https://qiniu.youjiamall.cn/zhai_pei.xlsx')
            }
        },
        handlePageChange(page) {
            this.queryData.page = page - 1;
            this.currentPage = page;
            this.getAddressList()
        },
        getQiniuToken() {
            if (!this.getQiniuTokenIng) {
                this.getQiniuTokenIng = true
                this.loading = true
                this.loadingText = 'token加载中'
                request(process.env.VUE_APP_API_BASE_URL + 'sfa/qiniu/token/yjhp-hb', 'get').then((res) => {
                    if (res.data.code == 200) {
                        this.postData.token = res.data.data
                        this.getQiniuTokenIng = false
                    }
                })
            }
        },
        // 让父组件使用该方法新增的时候重置已添加列表
        initAddress(data) {
            if (!data) {
                this.queryData = {
                    page: 0,
                    size: 10,
                }
                this.batchId = ""
                this.addressType = 1;
                this.form.addressTypeInfo = ""
                this.dataSource = []
            } else {
                this.queryData = {
                    page: 0,
                    size: 10,
                }
                this.batchId = ""
                this.addressType = data.addressType;
                this.form.addressTypeInfo = data.addressTypeInfo
                this.dataSource = []
            }

        },
        // 地址列表
        getAddressList() {
            console.log(this.needId, 'this.needId')
            this.tabLoading = true;
            if (this.needId) {
                this.queryData.needOrderDetailId = this.needId;
            } else {
                delete this.queryData.needOrderDetailId
                if (this.batchId) {
                    this.queryData.batchId = this.batchId;
                }
            }
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderAddress/list', 'post', this.queryData).then(res => {
                let data = res.data.data;
                this.tabLoading = false;
                if (res.data.code == 200) {
                    this.dataSource = data.data;
                    this.queryData.page = data.page;
                    this.queryData.size = data.size;
                    this.total = data.total;
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },
        // 地址倒入
        importAddress(e) {
            let formData = new FormData();
            formData.append('file', e.file.originFileObj);
            // formData.append('fileName', e.file.name);
            // formData.append('fileType', e.file.type);
            if (this.needId) {
                formData.append('needOrderDetailId', this.needId)
            } else {
                if (this.batchId) {
                    formData.append('batchId', this.batchId)
                }
            }
            let subType = 0;
            if (this.type == 1) {
                subType = 101
            } else {
                subType = 102
            }
            formData.append('subType', subType);
            console.log(this.needId, this.batchId, 'this.needId')
            this.tabLoading = true;
            // 使用 axios 或其他请求方法发送文件
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderAddress/importAddressExcel', 'post', formData)
                .then(res => {
                    this.tabLoading = false;
                    if (res.data.code == 200) {
                        if (!this.batchId) {
                            this.batchId = res.data.data;
                            this.$emit('sendAddress', this.batchId)
                        } else {
                            this.$emit('sendAddress', this.batchId)
                        }
                        this.getAddressList()
                    } else {
                        this.$message.error(res.data.message)
                    }

                })
                .catch(error => {
                    console.error(error);
                });
        },
        // 输入暂无地址
        addressInfoBlur(e) {
            this.$emit('sendAddress', e.target.value)
        },
        changeAddressType(e) {
            this.$emit('sendAddressType', e.target.value)
        },
        dateChange(date, dateString) {
            console.log(dateString, 'dateString')
            if (dateString) {
                this.addressForm.expectStartTime = dateString[0] + ':00'
                this.addressForm.expectEndTime = dateString[1] + ':00'
                this.dateValue = dateString
            } else {
                this.addressForm.expectStartTime = ""
                this.addressForm.expectEndTime = ""
                this.dateValue = ""
            }

        },
        getCity() {
            request(process.env.VUE_APP_API_BASE_URL + 'api/adcode/list/0', 'get').then(res => {
                if (res.data.code == 200) {
                    this.prov = res.data.data;
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },
        provChange(id) {
            this.city = []
            this.area = []
            if (!id) {
                return false;
            }
            const selProv = this.prov.find(item => item.id === id);
            this.addressForm.provinceId = selProv.id;
            this.addressForm.proveName = selProv.name;
            delete this.addressForm.cityDesc
            delete this.addressForm.areaDesc
            request(process.env.VUE_APP_API_BASE_URL + 'api/adcode/list/' + id, 'get').then(res => {
                if (res.data.code == 200) {
                    this.city = res.data.data;
                } else {
                    this.$message.error(res.data.message);
                }
            })
        },
        cityChange(id) {
            if (!id) {
                return false;
            }
            this.area = []
            delete this.addressForm.areaDesc
            const selProv = this.city.find(item => item.id === id);
            this.addressForm.cityId = selProv.id;
            this.addressForm.cityName = selProv.name;
            request(process.env.VUE_APP_API_BASE_URL + 'api/adcode/list/' + id, 'get').then(res => {
                if (res.data.code == 200) {
                    this.area = res.data.data;
                } else {
                    this.$message.error(res.data.message);
                }
            })
        },
        areaChange(id) {
            const selProv = this.area.find(item => item.id === id);
            this.addressForm.areaId = selProv.id;
            this.addressForm.areaName = selProv.name;
            this.areaId = id;
        },
        edit(record) {
            let data = JSON.parse(JSON.stringify(record));
            console.log(data, 'data')
            this.title = '编辑'
            this.dateValue = ""
            if (data.expectStartTime && data.expectEndTime) {
                this.dateValue = [data.expectStartTime, data.expectEndTime]
            }
            this.addressForm = data;
            this.openAddress = true;
            request(process.env.VUE_APP_API_BASE_URL + 'api/adcode/list/' + data.provinceId, 'get').then(res => {
                if (res.data.code == 200) {
                    this.city = res.data.data;
                } else {
                    this.$message.error(res.data.message);
                }
            })
            request(process.env.VUE_APP_API_BASE_URL + 'api/adcode/list/' + data.cityId, 'get').then(res => {
                if (res.data.code == 200) {
                    this.area = res.data.data;
                } else {
                    this.$message.error(res.data.message);
                }
            })
        },
        del(record) {
            // if (!this.needId) {
            //     this.dataSource.find((item, index) => {
            //         if (item.id == record.id) {
            //             this.dataSource.splice(index, 1)
            //         }
            //     })
            // } else {
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderAddress/del/' + record.id, 'post', {}).then(res => {
                if (res.data.code == 200) {
                    this.$message.success('删除成功')
                    this.getAddressList()
                } else {
                    this.$message.error(res.data.message);
                }
            })
            // }

        },
        handleOkAddress() {
            this.$refs.ruleAddForm.validate(valid => {
                if (valid) {
                    if (this.type == 1) {
                        if (!this.addressForm.expectStartTime || !this.addressForm.expectEndTime) {
                            this.$message.error('请选择预计送达时间')
                            return false;
                        }
                    }
                    this.openAddress = false;
                    if (this.needId) {
                        this.addressForm.needOrderDetailId = this.needId
                    } else {
                        if (this.batchId) {
                            this.addressForm.batchId = this.batchId
                        }
                    }
                    if (!this.addressForm.id) {
                        request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderAddress/create', 'post', this.addressForm).then(res => {
                            if (res.data.code == 200) {
                                this.$message.success('添加成功')
                                this.batchId = res.data.data;
                                this.$emit('sendAddress', this.batchId)
                                console.log(this.batchId)
                                this.getAddressList()
                            } else {
                                this.$message.error(res.data.message);
                            }
                        })
                    } else {
                        request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderAddress/update', 'post', this.addressForm).then(res => {
                            if (res.data.code == 200) {
                                this.$message.success('修改成功')
                                this.getAddressList()
                            } else {
                                this.$message.error(res.data.message);
                            }
                        })
                    }
                } else {
                    return false;
                }
            });
        },
        cancelChangeAddress() {
            this.openAddress = false
        },
        addAddress() {
            this.openAddress = true;
            this.dateValue = ""
            this.addressForm = {};
        },
    }
}
</script>
<style scoped>
.title1 {
    padding: 0;
    margin: 0;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 15px;
    color: #010101;
}

.page-box {
    display: flex;
    align-items: center;
    justify-content: right;
    padding: 10px;
}
</style>